import { AbstractValidator, ValidationResult } from 'fluent-ts-validator';
import i18n from '../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore, QueryResult } from '../dataStore';
import { FormStore } from '../formStore';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { CommandResult } from 'stores/types';

import { PracticalFormItemDto } from 'stores/assessments/forms/practical-form-store';

export interface EventPracticalTemplatesDto {
  templateId: string;
  templateTitle: string;
  practicalForms: PracticalFormItemDto[];
}

export interface CreatePracticalFormDto {
  eventId: string;
  practicalTemplateId: string;
}

export class CreatePracticalFormValidator extends AbstractValidator<CreatePracticalFormDto> {
  constructor() {
    super();
    this.validateIfString(o => o.eventId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Event Id is required'));

    this.validateIfString(o => o.practicalTemplateId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Practical Template Id is required'));
  }
}

export const toCreatePracticalItemDto = ({ templateId }: EventPracticalTemplatesDto, eventId: string): CreatePracticalFormDto => ({
  eventId: eventId,
  practicalTemplateId: templateId
});

@repository('@@FORMPRACTICALLIST', 'formpracticallist.summary')
export class FormPracticalListStore extends DataStore<EventPracticalTemplatesDto> {
  RETRIEVE_ONE_PRACTICAL_FORM = 'RETRIEVE_ONE_PRACTICAL_FORM';
  baseUrl = 'events/v1';
  createPath = 'new-practical-forms';
  retrievePath = 'get-practical-forms';
  updatePath = '';
  deletePath = '';
  retrieveOnePath = 'get-practical-form';
  retriveTemplatesByEventPath = 'get-practical-templates';
  retrieveFormById = 'v1/get-practical-form/{id}';

  protected validate(item: EventPracticalTemplatesDto): ValidationResult {
    const createPracticalItemDto = toCreatePracticalItemDto(this.state.item, this.state.item.id);
    return new CreatePracticalFormValidator().validate(createPracticalItemDto);
  }

  public async getTemplatesByEventId(id: string, eventStatus, complete: boolean = true): Promise<any> {
    const httpService = container.get(HttpService);
    const complParam = complete ? '' : `?complete=${complete}`;
    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<EventPracticalTemplatesDto>>(
        `${this.baseUrl}/events/${id}/${this.retriveTemplatesByEventPath}${complParam}`
      )
    );
    return result;
  }

  public async getFormById(id: string): Promise<PracticalFormItemDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.RETRIEVE_ONE_PRACTICAL_FORM,
      httpService.get<PracticalFormItemDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  constructor() {
    super('FORMPRACTICALLIST', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@FORMPRACTICALLIST', 'formpracticallist.event-tab-summary')
export class EventPracticalTabStore extends DataStore<EventPracticalTemplatesDto> {
  RETRIEVE_ONE_PRACTICAL_FORM = 'RETRIEVE_ONE_PRACTICAL_FORM';
  baseUrl = 'events/v1';
  createPath = 'new-practical-forms';
  retrievePath = 'get-practical-forms';
  updatePath = '';
  deletePath = '';
  retrieveOnePath = 'get-practical-form';
  retriveTemplatesByEventPath = 'get-practical-templates';
  retrieveFormById = 'v1/get-practical-form/{id}';
  lastParamQuery: string = null;

  protected validate(item: EventPracticalTemplatesDto): ValidationResult {
    const createPracticalItemDto = toCreatePracticalItemDto(this.state.item, this.state.item.id);
    return new CreatePracticalFormValidator().validate(createPracticalItemDto);
  }

  public async getTemplatesByEventId(id: string, eventStatus, complete: boolean = true): Promise<any> {
    let paramsString = `${id}_${eventStatus}_${complete}`;

    if (this.state.items.length <= 0 || this.lastParamQuery !== paramsString) {
      const httpService = container.get(HttpService);
      const complParam = complete ? '' : `?complete=${complete}`;
      const result = await this.dispatchAsync(
        this.ENTITY_LIST_UPDATE,
        httpService.get<QueryResult<EventPracticalTemplatesDto>>(
          `${this.baseUrl}/events/${id}/${this.retriveTemplatesByEventPath}${complParam}`
        )
      );
      this.lastParamQuery = paramsString;

      return result;
    }
  }

  public async getFormById(id: string): Promise<PracticalFormItemDto> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.RETRIEVE_ONE_PRACTICAL_FORM,
      httpService.get<PracticalFormItemDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`)
    );
    return result.data;
  }

  constructor() {
    super('EVENTPRACTICALTAB', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@FORMPRACTICALLIST', 'formpracticallist.new')
export class NewPracticalFormUserStore extends FormStore<CreatePracticalFormDto> {
  baseUrl = 'events/v1';
  createPath = 'new-practical-forms';
  retrievePath = '';
  updatePath = '';

  protected validate(item: CreatePracticalFormDto) {
    return new CreatePracticalFormValidator().validate(item);
  }

  public createForms = async () => {
    const httpService = container.get(HttpService);
    const validation = this.validate(this.state.item);
    if (validation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validation);
      return;
    }
    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.post<CreatePracticalFormDto, CommandResult<CreatePracticalFormDto>>(`${this.baseUrl}/${this.createPath}`, this.state.item)
    );
    return result.data;
  };

  constructor() {
    super('NEW_FORMPRACTICALLIST', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}
