import { repository } from 'redux-scaffolding-ts';
import { FormStore } from 'stores/formStore';
import { ValidationResult, AbstractValidator } from 'fluent-ts-validator';
import { CommandResult } from 'stores/types';
import { container } from 'inversify.config';
import { MachineListItemViewModel } from 'widgets/items-list/machine-list-item-deprecated';
import HttpService from 'services/http-service';
import { CreateInstructorExpertiseRequestDto } from './instructor-expertise-request-store';
import i18n from 'i18n';

export interface InstructorExpertiseRequestsFormDto {
  instructorIds: string[];
  expertises: CreateInstructorExpertiseRequestDto[];
}

export class InstructorExpertiseRequestFormValidator extends AbstractValidator<InstructorExpertiseRequestsFormDto> {
  constructor() {
    super();

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .withFailureMessage(i18n.t('Invalid data'));
  }
}

@repository('@@INSTRUCTOR_EXPERTISE_REQUESTS', 'instructorExpertiseRequests.instructorExpertiseRequest-form')
export class InstructorExpertiseRequestFormStore extends FormStore<InstructorExpertiseRequestsFormDto> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = '';
  updatePath = '';
  retrieveOnePath = '';
  createInstructorExpertisesRequestsPath = 'v1/create-instructor-expertises-requests';

  constructor() {
    super('NEW_INSTRUCTOR_EXPERTISE_REQUEST_FORM', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }

  protected validate(item: InstructorExpertiseRequestsFormDto): ValidationResult {
    return new InstructorExpertiseRequestFormValidator().validate(item);
  }

  private isNullOrEmptyOrWhitespace(input: string | null | undefined): boolean {
    return !input || input.trim().length === 0;
  }

  public async createInstructorExpertiseRequest(
    instructorIds: string[],
    machines: MachineListItemViewModel[],
    eventTypeId: string,
    roleId: string,
    trainingLevelId: string,
    originalEventTypeId: string
  ) {
    const createInstructorExpertiseRequestDto: CreateInstructorExpertiseRequestDto[] = (machines || [])
      .filter(x => x.machineModel != null)
      .map(machine => {
        return {
          originalEventTypeId: !this.isNullOrEmptyOrWhitespace(originalEventTypeId) ? originalEventTypeId : eventTypeId,
          eventTypeId: eventTypeId,
          roleId: roleId,
          trainingLevelId: trainingLevelId,
          isMachineRelated: true,

          machineModelId: machine.machineModel.id,
          machineRelatedClusterId: machine.cluster.id,
          equipmentTypeId: machine.equipmentType.id,
          oemId: machine.oem.id,
          plcTypes: machine.plcTypes
        } as CreateInstructorExpertiseRequestDto;
      });

    const instructorExpertiseRequestsFormDto: InstructorExpertiseRequestsFormDto = {
      instructorIds: instructorIds,
      expertises: createInstructorExpertiseRequestDto
    };

    const httpService = container.get<HttpService>(HttpService);
    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.post<InstructorExpertiseRequestsFormDto, CommandResult<InstructorExpertiseRequestsFormDto>>(
        `${this.baseUrl}/${this.createInstructorExpertisesRequestsPath}`,
        instructorExpertiseRequestsFormDto
      )
    );
    return result.data;
  }
}
