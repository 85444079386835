import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from '../../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';
import { InstructorVisaDto } from 'stores/skills/instructor-visa-store';
import { RestrictionWarningDto } from './restrictions-warnings-store';
import { DateTimeService } from 'services/datetime-service';

export interface InstructorVisaRestrictionDto extends BaseDto {
  instructorVisa: InstructorVisaDto;
  restriction: RestrictionWarningDto;
}

export interface CreateInstructorVisaRestrictionDto {}

export interface ChangeInstructorVisaRestrictionDto extends InstructorVisaRestrictionDto {}

export class InstructorVisaRestrictionValidator extends AbstractValidator<InstructorVisaRestrictionDto> {
  constructor() {
    super();

    this.validateIfString(o => o.instructorVisa.instructorId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Instructor is required'));

    this.validateIfString(o => o.instructorVisa.ttcLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('TTC Location is required'));

    this.validateIfString(o => o.instructorVisa.travelingLocationId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => !x.instructorVisa.visaTreatyRequired)
      .withFailureMessage(i18n.t('Traveling Location is required'));

    this.validateIfString(o => o.instructorVisa.visaId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Visa is required'));

    // this.validateIf(o => o.instructorVisa.startDate)
    //   .isNotEmpty()
    //   .isNotNull()
    //   .when(x => x.restriction.visaName !== 'None' && !x.restriction.isRestriction && !x.instructorVisa.isInstructorRestriction)
    //   .withFailureMessage(i18n.t('Start Date is required'));

    // this.validateIf(o => o.instructorVisa.expireDate)
    //   .isNotEmpty()
    //   .isNotNull()
    //   .when(x => x.restriction.visaName !== 'None' && !x.restriction.isRestriction && !x.instructorVisa.isInstructorRestriction)
    //   .withFailureMessage(i18n.t('Expire Date is required'));

    // this.validateIfDate(o => new Date(o.instructorVisa.startDate))
    //   .isDefined()
    //   .isNotNull()
    //   .when(x => x.restriction.visaName !== 'None' && !x.restriction.isRestriction && !x.instructorVisa.isInstructorRestriction)
    //   .withFailureMessage(i18n.t('Start Date is required'));

    // this.validateIfDate(o => new Date(o.instructorVisa.expireDate))
    //   .isDefined()
    //   .isNotNull()
    //   .when(x => x.restriction.visaName !== 'None' && !x.restriction.isRestriction && !x.instructorVisa.isInstructorRestriction)
    //   .withFailureMessage(i18n.t('Expire Date is required'));

    this.validateIf(o =>
      DateTimeService.toMoment(new Date(o.instructorVisa.expireDate)).isSame(
        DateTimeService.toMoment(new Date(o.instructorVisa.startDate)),
        'day'
      )
    )
      .isEqualTo(false)
      .withFailureMessage(i18n.t('Start Date and Expire Date are equals'));

    this.validateIf(o =>
      DateTimeService.toMoment(new Date(o.instructorVisa.expireDate)).isBefore(
        DateTimeService.toMoment(new Date(o.instructorVisa.startDate)),
        'day'
      )
    )
      .isEqualTo(false)
      .withFailureMessage(i18n.t('Start Date is greater than Expire Date'));

    this.validateIfString(o => o.restriction.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Id is required'));

    this.validateIfString(o => o.restriction.ttcLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('TTC Location is required'));

    this.validateIfString(o => o.restriction.travelingLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Traveling Location is required'));

    this.validateIfString(o => o.restriction.visaId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Visa Requirement is required'));

    this.validateIfNumber(o => o.restriction.visaApplicationDuration)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Visa application. Months duration is required'));

    this.validateIfNumber(o => o.restriction.visaApplicationDuration)
      .isDefined()
      .isNotNull()
      .isGreaterThanOrEqual(0)
      .withFailureMessage(i18n.t('Visa application. Months duration is lower than 0 months'));

    this.validateIfString(o => o.restriction.comments)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Comments is required'));

    this.validateIfString(o => o.restriction.contractId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Contract is required'));

    this.validateIfString(o => o.restriction.flightCostId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Flight Cost is required'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(
        o => o.restriction.ttcLocationId && o.instructorVisa.ttcLocationId && o.restriction.ttcLocationId === o.instructorVisa.ttcLocationId
      )
      .withFailureMessage(i18n.t('TTC locations for Visa and Restrictions are differents'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(
        o =>
          o.restriction.travelingLocationId &&
          o.instructorVisa.travelingLocationId &&
          o.restriction.travelingLocationId === o.instructorVisa.travelingLocationId
      )
      .when(x => !x.instructorVisa.visaTreatyRequired)
      .withFailureMessage(i18n.t('Traveling locations for Visa and Restrictions are differents'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(o => o.restriction.visaId && o.instructorVisa.visaId && o.restriction.visaId === o.instructorVisa.visaId)
      .withFailureMessage(i18n.t('Visa Restrictions for Visa and Restrictions are differents'));
  }
}

export class ChangeInstructorVisaRestrictionValidator extends AbstractValidator<ChangeInstructorVisaRestrictionDto> {
  constructor() {
    super();

    this.validateIfString(o => o.instructorVisa.instructorId)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Instructor is required'));

    this.validateIfString(o => o.instructorVisa.ttcLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('TTC Location is required'));

    this.validateIfString(o => o.instructorVisa.travelingLocationId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => !x.instructorVisa.visaTreatyRequired)
      .withFailureMessage(i18n.t('Traveling Location is required'));

    this.validateIfString(o => o.instructorVisa.visaId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Visa is required'));

    // this.validateIf(o => o.instructorVisa.startDate)
    //   .isNotEmpty()
    //   .isNotNull()
    //   .when(x => x.restriction.visaName !== 'None' && !x.restriction.isRestriction && !x.instructorVisa.isInstructorRestriction)
    //   .withFailureMessage(i18n.t('Start Date is required'));

    // this.validateIf(o => o.instructorVisa.expireDate)
    //   .isNotEmpty()
    //   .isNotNull()
    //   .when(x => x.restriction.visaName !== 'None' && !x.restriction.isRestriction && !x.instructorVisa.isInstructorRestriction)
    //   .withFailureMessage(i18n.t('Expire Date is required'));

    // this.validateIfDate(o => new Date(o.instructorVisa.startDate))
    //   .isDefined()
    //   .isNotNull()
    //   .when(x => x.restriction.visaName !== 'None' && !x.restriction.isRestriction && !x.instructorVisa.isInstructorRestriction)
    //   .withFailureMessage(i18n.t('Start Date is required'));

    // this.validateIfDate(o => new Date(o.instructorVisa.expireDate))
    //   .isDefined()
    //   .isNotNull()
    //   .when(x => x.restriction.visaName !== 'None' && !x.restriction.isRestriction && !x.instructorVisa.isInstructorRestriction)
    //   .withFailureMessage(i18n.t('Expire Date is required'));

    this.validateIf(o =>
      DateTimeService.toMoment(new Date(o.instructorVisa.expireDate)).isSame(
        DateTimeService.toMoment(new Date(o.instructorVisa.startDate)),
        'day'
      )
    )
      .isEqualTo(false)
      .withFailureMessage(i18n.t('Start Date and Expire Date are equals'));

    this.validateIf(o =>
      DateTimeService.toMoment(new Date(o.instructorVisa.expireDate)).isBefore(
        DateTimeService.toMoment(new Date(o.instructorVisa.startDate)),
        'day'
      )
    )
      .isEqualTo(false)
      .withFailureMessage(i18n.t('Start Date is greater than Expire Date'));

    this.validateIfString(o => o.restriction.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Restriction Id is required'));

    this.validateIfString(o => o.restriction.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Id is required'));

    this.validateIfString(o => o.restriction.ttcLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('TTC Location is required'));

    this.validateIfString(o => o.restriction.travelingLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Traveling Location is required'));

    this.validateIfString(o => o.restriction.visaId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Visa Requirement is required'));

    this.validateIfNumber(o => o.restriction.visaApplicationDuration)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Visa application. Months duration is required'));

    this.validateIfNumber(o => o.restriction.visaApplicationDuration)
      .isDefined()
      .isNotNull()
      .isGreaterThanOrEqual(0)
      .withFailureMessage(i18n.t('Visa application. Months duration is lower than 0 months'));

    this.validateIfString(o => o.restriction.comments)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Comments is required'));

    this.validateIfString(o => o.restriction.contractId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Contract is required'));

    this.validateIfString(o => o.restriction.flightCostId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Flight Cost is required'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(
        o => o.restriction.ttcLocationId && o.instructorVisa.ttcLocationId && o.restriction.ttcLocationId === o.instructorVisa.ttcLocationId
      )
      .withFailureMessage(i18n.t('TTC locations for Visa and Restrictions are differents'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(
        o =>
          o.restriction.travelingLocationId &&
          o.instructorVisa.travelingLocationId &&
          o.restriction.travelingLocationId === o.instructorVisa.travelingLocationId
      )
      .when(x => !x.instructorVisa.visaTreatyRequired)
      .withFailureMessage(i18n.t('Traveling locations for Visa and Restrictions are differents'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(o => o.restriction.visaId && o.instructorVisa.visaId && o.restriction.visaId === o.instructorVisa.visaId)
      .withFailureMessage(i18n.t('Visa Restrictions for Visa and Restrictions are differents'));
  }
}

@repository('@@MASTERDATA', 'instructorVisaRestriction.summary')
export class InstructorVisaRestrictionStore extends DataStore<InstructorVisaRestrictionDto> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = 'v1/get-instructor-visa-restrictions';
  updatePath = 'v1/update-instructor-visa-restriction';
  deletePath = '';

  protected validate(item: InstructorVisaRestrictionDto) {
    return new InstructorVisaRestrictionValidator().validate(item);
  }

  constructor() {
    super('MASTERDATA', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@MASTERDATA', 'instructorVisaRestriction.change')
export class ChangeInstructorVisaRestrictionStore extends FormStore<ChangeInstructorVisaRestrictionDto> {
  baseUrl = 'skills';
  createPath = '';
  retrievePath = 'v1/get-instructor-visa-restrictions';
  updatePath = 'v1/update-instructor-visa-restriction';

  protected validate(item: ChangeInstructorVisaRestrictionDto) {
    return new ChangeInstructorVisaRestrictionValidator().validate(item);
  }

  constructor() {
    super('CHANGE_MASTERDATA', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
