import * as autobind from 'autobind';
import React, { Component, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Input, Button, Form, Divider, Dimmer, Loader, Icon, Checkbox } from 'semantic-ui-react';
import EventInstructorsSelector from 'widgets/bussiness/selectors/instructors-selector';
import {
  DateTimePeriod,
  InstructorTabEventsStore,
  EventDto,
  EventInstructorDto,
  EventUserDto,
  TravelRangeDto,
  ChangeUnblockButtonsStore,
  EventStatus
} from 'stores/events/events-store';
import PeriodInput from 'widgets/form/period-input';
import { ItemReference } from 'stores/dataStore';
import { IdentityService, Roles } from 'services/identity-service';
import { resolve } from 'inversify-react';
import ChooseAllInstructorsView from 'widgets/form/choose-all-instructors-form';
import EventInstructorRoleEditor from 'widgets/bussiness/event-instructor-role-editor';
import { InstructorTabExpertiseStore } from 'stores/skills/instructor-expertise-store';
import './instructor-tab-pane.less';
import { connect } from 'redux-scaffolding-ts';
import { DateTimeService } from 'services/datetime-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { EventFormStore, EventInstructorViewModel } from 'stores/events/event-form-store';
import LocationEditor from 'widgets/bussiness/location-editor';
import { getNextStatus, isOfCategory } from 'utils/event-utils';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { EventTypeCategory } from 'stores/configuration/events-workflow/event-types-store';
import { RoleInLocationDto, UserDto, UserStore } from 'stores/users/users-store';
import { isUserEventOwner } from 'utils/event-utils';
import { DatesRangeInput } from 'widgets/form/datesRangeInput';
import { getEventOwners } from 'utils/event-type-utils';
import AddExpertisePopup from '../../popups/add-expertise-popup/add-expertise-popup';
import { MachineListItemViewModel } from 'widgets/items-list/machine-list-item-deprecated';
import PlannerAssistant from 'site/pages/planner-assistant/planner-assistant';
import { InstructorDto, InstructorStore } from 'stores/instructors/instructors-store';

interface InstructorTabPaneProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  isTabReadonly: boolean;
  showWorkingDays?: boolean;
  readOnly: boolean;
  events?: InstructorTabEventsStore;
  eventFormStore?: EventFormStore;
  instructorExpertiseStore?: InstructorTabExpertiseStore;
  changeUnblockButtonsStore?: ChangeUnblockButtonsStore;
  usersStore?: UserStore;
  selectedEvent?: EventDto;
  instructorStore?: InstructorStore;
}

interface InstructorTabPaneState {
  intructorSelectorShown: boolean;
  notConflictOtherEventsDates?: boolean;
  recomendedInstructorsByExpertise: EventUserDto[];
  canAddInstructor: boolean;
  showAddExpertiseModal: boolean;
  instructorExpertise: UserDto;
  multipleInstructorsExpertise: string[];
  instructorExpertiseDefined: boolean;
  modalPlannerAssistant: boolean;
  loadingSelectedInstructorInfo: boolean;
}

interface EventPerInstructor {
  event: EventDto;
  instructor: EventInstructorDto;
}

@connect(
  ['instructorExpertiseStore', InstructorTabExpertiseStore],
  ['events', InstructorTabEventsStore],
  ['eventFormStore', EventFormStore],
  ['changeUnblockButtonsStore', ChangeUnblockButtonsStore],
  ['usersStore', UserStore],
  ['instructorStore', InstructorStore]
)
class InstructorTabPane extends Component<InstructorTabPaneProps, InstructorTabPaneState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  private get changeUnblockButtonsStore() {
    return this.props.changeUnblockButtonsStore;
  }

  state: InstructorTabPaneState = {
    intructorSelectorShown: false,
    notConflictOtherEventsDates: false,
    recomendedInstructorsByExpertise: [],
    canAddInstructor: (this.props.eventFormStore.state.item.instructors || []).length > 0 ? false : true,
    loadingSelectedInstructorInfo: false,
    modalPlannerAssistant: false,
    showAddExpertiseModal: false,
    instructorExpertise: null,
    multipleInstructorsExpertise: [],
    instructorExpertiseDefined: false
  };

  componentDidMount() {
    if (!this.props.isTabReadonly && !this.props.readOnly) {
      this.getRecomendedInstructorsByExpertise();
    }
    if (
      this.props.eventFormStore.state.item &&
      this.props.eventFormStore.state.item.instructors &&
      this.props.eventFormStore.state.item.instructors.length > 0
    ) {
      this.validateInstructorFromInstructorViewModel(this.props.eventFormStore.state.item.instructors, 0, true);
    }
  }

  private buildODataQueryFilter() {
    // const { eventDetails, trainingDetails, eventType } = this.props.eventFormStore.state.item;

    // const filters: any[] = [{ EventTypeId: { eq: { type: 'guid', value: eventType.id } } }];

    // if (eventDetails?.profession?.id)
    //   filters.push({ or: [{ RoleId: { eq: null } }, { RoleId: { eq: { type: 'guid', value: eventDetails.profession.id } } }] });

    // if (trainingDetails?.trainingLevel?.id)
    //   filters.push({
    //     or: [{ TrainingLevelId: { eq: null } }, { TrainingLevelId: { eq: { type: 'guid', value: trainingDetails.trainingLevel.id } } }]
    //   });

    // const hasFuncSubArea = eventDetails?.nmrFunctionalArea && eventDetails?.nmrFunctionalSubArea?.id;
    // const hasMachineModel = (eventDetails?.machines || []).length !== 0;

    // if (eventDetails?.machineRelated && hasMachineModel)
    //   filters.push({
    //     and: [
    //       {
    //         or: [
    //           { MachineModelId: { eq: null } },
    //           {
    //             MachineModelId:
    //               (eventDetails.machines || []).filter(machine => !!machine.machineModel).length > 0
    //                 ? {
    //                     in: {
    //                       type: 'guid',
    //                       value: eventDetails.machines.filter(machine => !!machine.machineModel).map(x => x.machineModel.id)
    //                     }
    //                   }
    //                 : { eq: null }
    //           }
    //         ]
    //       },
    //       { NMRFunctionalSubAreaId: { eq: null } }
    //     ]
    //   });

    // if (eventDetails && !eventDetails.machineRelated && hasFuncSubArea) {
    //   filters.push({
    //     and: [
    //       {
    //         or: [
    //           { NMRFunctionalSubAreaId: { eq: null } },
    //           { NMRFunctionalSubAreaId: { eq: { type: 'guid', value: eventDetails?.nmrFunctionalSubArea?.id || null } } }
    //         ]
    //       },
    //       { MachineModelId: { eq: null } }
    //     ]
    //   });
    // }

    // return filters;

    const filters: any[] = [];
    if (this.isPoc()) {
      filters.push({ instructorRole: { name: { eq: 'Internal Trainer' } } });
    }
    return filters;
  }

  private getRecomendedInstructorsByExpertise() {
    this.setState({ canAddInstructor: false });

    const query = {
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [],
      filter: this.buildODataQueryFilter()
    };

    this.props.instructorStore.getAllAsync(query).then(instructor => {
      let recomendedInstructors: EventUserDto[] = instructor.items.map(x => ({
        firstName: x.name,
        id: x.userId,
        lastName: x.surname,
        location: x.location,
        pillar: x.pillar
      }));

      this.setState({
        recomendedInstructorsByExpertise: recomendedInstructors,
        canAddInstructor: true
      });
    });
  }

  private mapToEventInstructor(events: EventDto[]) {
    const eventsPerInstructor: EventPerInstructor[] = [];
    events.forEach(event => eventsPerInstructor.push(...event.instructors.map(instructor => ({ event, instructor }))));
    return eventsPerInstructor;
  }

  arraysAreEquals = (array_1, array_2) => array_1.length === array_2.length && array_1.every((v, i) => v === array_2[i]);

  private async getInstructorEventsByIds(ids: string[]) {
    this.setState({ canAddInstructor: false });
    let idsFilter = ids.map(id => {
      return { InstructorId: { eq: { type: 'guid', value: id } } };
    });
    const filter = [
      {
        eventInstructors: {
          any: {
            or: idsFilter
          }
        }
      }
    ];
    const queryEvents = {
      searchQuery: '',
      filter,
      orderBy: [],
      skip: 0,
      take: 100000,
      select: 'id,FriendlyEventId,startDate, endDate'
    };
    this.setState({ canAddInstructor: true });
    return this.props.events.getAllAsync(queryEvents);
  }

  private checkWarningMessages(instructors: EventInstructorViewModel[]): string[] {
    let notInDate: boolean = false;
    let notValid: boolean = false;
    let notConflict: boolean = false;
    const messages: string[] = [];

    for (const instructor of instructors) {
      if (!!instructor.notInDate) notInDate = true;
      if (!!instructor.notValid) notValid = true;
      if (!!instructor.notConflict) notConflict = true;
    }

    if (notInDate) messages.push('There is a conflict with the Dates');

    if (notValid) messages.push("There is a conflict with the Intructor's Expertise");

    if (notConflict) messages.push("There is a conflict with others Instructor's Events");

    return messages;
  }

  async getEventInstructorAvailability(
    newInstructors: InstructorDto[],
    index: number,
    multiple: boolean = false
  ): Promise<EventInstructorViewModel[]> {
    const { startDate, endDate } = this.props.eventFormStore.state.item;
    const instructorIds = multiple ? newInstructors.map(x => x.userId) : [newInstructors[index].userId];
    const eventsInstructors = await this.getInstructorEventsByIds(instructorIds).then(events => this.mapToEventInstructor(events.items));
    const instructorId = newInstructors[index].userId;
    const finalEvents = eventsInstructors.filter(x => x.instructor.instructorId === instructorId);

    const newInstructorRows = newInstructors.map((instructorDto: InstructorDto) => {
      const newInstructorRow: EventInstructorViewModel = {
        location: {
          id: instructorDto?.location?.id || '0',
          title: instructorDto?.location?.location || ''
        },
        period: { from: startDate, to: endDate },
        role: undefined,
        user: instructorDto.user,
        workingDays: null,
        workingHours: null,
        travelDays: null,
        notValid: false,
        notInDate: false,
        notConflict: false,
        noTravel: false
      };

      newInstructorRow.notValid = !this.isRecommendedInstructorToValided(newInstructorRow);
      newInstructorRow.notConflict = this.hasConflictsWithOtherEventInstructors(finalEvents);
      this.handleIfInstructorUserHasValidated(instructorId);
      return newInstructorRow;
    });
    return newInstructorRows;
  }

  private isPlannerTFT = () => {
    const userinfo = this.identityService.getUserInfo();
    return IdentityService.isPlannerTFT(userinfo);
  };

  private isPlannerMTC = () => {
    const userinfo = this.identityService.getUserInfo();
    return IdentityService.isPlannerMTC(userinfo);
  };

  private isAdmin = () => {
    const userinfo = this.identityService.getUserInfo();
    return IdentityService.isAdmin(userinfo);
  };

  private isPoc = () => {
    const userinfo = this.identityService.getUserInfo();
    return IdentityService.isPoc(userinfo);
  };

  private isEventOwner = (eventOwners: string[]) => {
    const userinfo = this.identityService.getUserInfo();
    return eventOwners.any(x => IdentityService.roles[Roles[x]] === userinfo.activeRole);
  };

  private async onSelectedInstructor(newInstructors: InstructorDto[]) {
    this.setState({ loadingSelectedInstructorInfo: true });
    const newInstructorRows = await this.getEventInstructorAvailability(newInstructors, 0);
    const warningMessages = this.checkWarningMessages(newInstructorRows);

    this.setState({ showAddExpertiseModal: true, multipleInstructorsExpertise: [], instructorExpertise: null });
    const newInstructorsExpertise = [];
    newInstructorRows.forEach(instructor => {
      newInstructorsExpertise.push(instructor.user.id);
    });
    this.setState({ showAddExpertiseModal: true, multipleInstructorsExpertise: newInstructorsExpertise });

    const instructors = [...this.props.eventFormStore.state.item.instructors, ...newInstructorRows];
    this.props.eventFormStore.change({ instructors, warningMessages });
    this.setState({ loadingSelectedInstructorInfo: false });
  }

  private hasConflictsWithOtherEventInstructors(eventsPerInstructor: EventPerInstructor[]): boolean {
    if (!this.props.eventFormStore?.state?.item) return false;

    const { startDate, endDate } = this.props.eventFormStore?.state?.item;

    return eventsPerInstructor.some(({ event }) =>
      DateTimeService.dateRangeOverlaps(
        DateTimeService.toDateRange(event.startDate, event.endDate),
        DateTimeService.toDateRange(startDate, endDate)
      )
    );
  }

  private isRecommendedInstructorToValided(instructor: EventInstructorViewModel): boolean {
    if (!instructor.user) return false;
    else return !!this.state.recomendedInstructorsByExpertise.find(x => x.id === instructor.user.id);
  }

  private isRecommendedInstructor(instructor: EventInstructorViewModel): boolean {
    if (!instructor.user) return false;
    return !!this.state.recomendedInstructorsByExpertise.find(x => x.id === instructor.user.id);
  }

  private handleOnLocationChange = (index: number, newLocation: ItemReference) => {
    const instructors = [...this.props.eventFormStore.state.item.instructors];
    instructors[index].location = newLocation;
    if (!newLocation) instructors[index].user = null;

    this.props.eventFormStore.change({ instructors });
  };

  private mapEventInstructorToInstructorExpertise(instructors: EventInstructorViewModel[]): InstructorDto[] {
    const instructorUsers = instructors.filter(x => !!x.user);
    const instructorExpertises = instructorUsers.map(x => {
      const instructorRole = {
        role: {
          name: 'Instructor'
        },
        location: x.user.location
      } as RoleInLocationDto;

      const model = { userId: x.user.id, user: { id: x.user.id, roles: [instructorRole] } };
      return model as InstructorDto;
    });
    return instructorExpertises;
  }

  private validateInstructorFromInstructorViewModel(instructors: EventInstructorViewModel[], index: number, multiple: boolean = false) {
    const instructorExpertises = this.mapEventInstructorToInstructorExpertise(instructors);
    this.getEventInstructorAvailability(instructorExpertises, index, multiple).then(eventInstructors => {
      if (eventInstructors && eventInstructors.length > 0) {
        if (multiple) {
          instructors.forEach((x, index) => {
            if (instructors[index]) {
              instructors[index].notConflict = eventInstructors[index]?.notConflict;
              instructors[index].notValid = eventInstructors[index]?.notValid;
            }
          });
        } else {
          instructors[index].notConflict = eventInstructors[index]?.notConflict;
          instructors[index].notValid = eventInstructors[index]?.notValid;
        }
      }
      const warningMessages = this.checkWarningMessages(instructors);
      this.props.eventFormStore.change({ instructors, warningMessages });
    });
  }

  private buildIsAlredyInstructorExpertiseDefinedFilter(
    instructor: UserDto,
    eventTypeId: string,
    roleId: string,
    trainingLevelId: string,
    machines: MachineListItemViewModel[]
  ) {
    const filters: any[] = [{ IsMachineRelated: { eq: true } }];
    filters.push({ InstructorId: { eq: { type: 'guid', value: instructor.id } } });
    filters.push({ EventTypeId: { eq: { type: 'guid', value: eventTypeId } } });
    filters.push({ RoleId: { eq: { type: 'guid', value: roleId } } });
    filters.push({ TrainingLevelId: { eq: { type: 'guid', value: trainingLevelId } } });

    const machine = machines.firstOrDefault();
    if (machine) {
      if (machine.cluster) filters.push({ MachineRelatedClusterId: { eq: { type: 'guid', value: machine.cluster.id } } });
      if (machine.equipmentType) filters.push({ EquipmentTypeId: { eq: { type: 'guid', value: machine.equipmentType.id } } });
      if (machine.oem) filters.push({ OemId: { eq: { type: 'guid', value: machine.oem.id } } });
      if (machine.machineModel) filters.push({ MachineModelId: { eq: { type: 'guid', value: machine.machineModel.id } } });
    }

    return filters;
  }

  private async IsAlredyInstructorExpertiseDefined(instructor: UserDto) {
    const { eventFormStore } = this.props;
    const isMachineRelated = eventFormStore.state.item.eventDetails ? eventFormStore.state.item.eventDetails.machineRelated : false;
    const machines = eventFormStore.state.item.eventDetails ? eventFormStore.state.item.eventDetails.machines : [];
    const eventTypeId = eventFormStore.state.item.eventType.id;
    const roleId = eventFormStore.state.item.eventDetails ? eventFormStore.state.item.eventDetails.profession.id : null;
    const trainingLevelId = eventFormStore.state.item.trainingDetails.trainingLevel
      ? eventFormStore.state.item.trainingDetails.trainingLevel.id
      : null;

    if (!isMachineRelated) return true;
    if ((machines || []).length !== 1) return false;

    let isAlredyInstructorExpertiseDefined: boolean = false;

    const query = {
      searchQuery: '',
      skip: 0,
      take: 100000,
      orderBy: [],
      filter: this.buildIsAlredyInstructorExpertiseDefinedFilter(instructor, eventTypeId, roleId, trainingLevelId, machines),
      select: 'id, InstructorId'
    };

    isAlredyInstructorExpertiseDefined = await this.props.instructorExpertiseStore.getAllAsync(query, true).then(instructorExpertises => {
      const instructorsByExpertise = instructorExpertises.items;
      return instructorsByExpertise.length > 0;
    });

    return isAlredyInstructorExpertiseDefined;
  }

  private getInstructor = async (id?: string): Promise<boolean> => {
    try {
      this.setState({
        instructorExpertiseDefined: false,
        canAddInstructor: false,
        multipleInstructorsExpertise: [],
        instructorExpertise: null
      });
      if (id) {
        const instructorData = await this.props.usersStore.getUserById(id);
        const instructorExpertiseDefined = await this.IsAlredyInstructorExpertiseDefined(instructorData);
        this.setState({
          instructorExpertise: instructorData,
          instructorExpertiseDefined: instructorExpertiseDefined,
          canAddInstructor: true
        });
      } else {
        this.setState({ instructorExpertise: null, instructorExpertiseDefined: false, canAddInstructor: true });
      }
    } catch {
      this.setState({ instructorExpertise: null, instructorExpertiseDefined: false, canAddInstructor: true });
      return false;
    }
    return true;
  };

  private handleOnInstructorUserChange = async (index: number, user: EventUserDto) => {
    const instructors = [...this.props.eventFormStore.state.item.instructors];
    instructors[index].user = user;

    this.setState({ showAddExpertiseModal: true });
    await this.getInstructor(instructors[index].user?.id);

    if (user?.location) instructors[index].location = { id: user.location.id, title: user.location.location };

    //DO NOT REMOVE. USE IF NECESSARY
    //this.handleIfInstructorUserHasValidated(user?.id);//> this call is unnecessary while validations are completely disabled since sprint 1 2024 update
    //this.validateInstructorFromInstructorViewModel(instructors, index);//> this call is removed because generate delays. NoConflict and NoValid are post calculated again.
    //DO NOT REMOVE. USE IF NECESSARY

    this.props.eventFormStore.change({ instructors });
  };

  private handleOnInstructorChange(index: number, propertyName: keyof EventInstructorViewModel, newValue: any) {
    const instructors = [...this.props.eventFormStore.state.item.instructors];
    instructors[index][propertyName as string] = newValue;

    this.props.eventFormStore.change({ instructors: instructors });
  }

  private handleOnPeriodChange = (index: number, period: DateTimePeriod) => {
    const { startDate, endDate, instructors: currInstructors } = this.props.eventFormStore.state.item;
    const isFromAfterTo = DateTimeService.toMoment(period.from).isAfter(period.to, 'D');
    const isFromBeforeStart = DateTimeService.toMoment(period.from).isBefore(startDate, 'D');
    const isFromAfterEnd = DateTimeService.toMoment(period.from).isAfter(endDate, 'D');
    const isToBeforeStart = DateTimeService.toMoment(period.to).isBefore(startDate, 'D');
    const isToAfterEnd = DateTimeService.toMoment(period.to).isAfter(endDate, 'D');
    const notInDate = isFromBeforeStart || isFromAfterEnd || isToBeforeStart || isToAfterEnd || isFromAfterTo;
    const instructors = (currInstructors || []).map((instructor, i) => {
      if (i === index) {
        const currInstructor = { ...instructor };
        if (notInDate) currInstructor.notInDate = notInDate;
        if (isFromBeforeStart || isFromAfterEnd) currInstructor.period = { ...currInstructor.period, from: startDate };
        else if (isToBeforeStart || isToAfterEnd) currInstructor.period = { ...currInstructor.period, to: endDate };
        else if (isFromAfterTo) currInstructor.period = { from: period.from, to: period.from };
        else currInstructor.period = period;
        return currInstructor;
      }
      return instructor;
    });

    this.props.eventFormStore.change({ instructors, fromScheduler: false });
  };

  private canEditWorkingTime({ user }: EventInstructorViewModel) {
    const userInfo = this.identityService.getUserInfo();
    const isAdminOrPoc = IdentityService.isAdminOrPoc(userInfo);
    const { eventFormStore, readOnly } = this.props;
    const eventNextStatus = (getNextStatus(eventFormStore.state.item) || '').toString();
    const isEO = isUserEventOwner(eventFormStore.state.item);
    return !readOnly && eventNextStatus === 'Closed' && (user?.id === this.identityService.userId || isEO || isAdminOrPoc);
  }

  private canEditWorkingDaysAndTravelDates({ user, noTravel }: EventInstructorViewModel) {
    const userInfo = this.identityService.getUserInfo();
    const isAdminOrPoc = IdentityService.isAdminOrPoc(userInfo);
    const { eventFormStore, isTabReadonly, readOnly } = this.props;
    const isEO = isUserEventOwner(eventFormStore.state.item);
    const canEditWorkingDays = (!isTabReadonly || !readOnly) && (user?.id === this.identityService.userId || isEO || isAdminOrPoc);

    return canEditWorkingDays && !noTravel;
  }

  private canEditWorkingDaysAndNoTravel({ user, travelDays }: EventInstructorViewModel) {
    const userInfo = this.identityService.getUserInfo();
    const isAdminOrPoc = IdentityService.isAdminOrPoc(userInfo);
    const { eventFormStore, isTabReadonly, readOnly } = this.props;
    const isEO = isUserEventOwner(eventFormStore.state.item);

    const canEditWorkingDays = (!isTabReadonly || !readOnly) && (user?.id === this.identityService.userId || isEO || isAdminOrPoc);
    return (
      canEditWorkingDays &&
      !travelDays?.departure?.from &&
      !travelDays?.departure?.to &&
      !travelDays?.arrival?.from &&
      !travelDays?.arrival?.to
    );
  }

  private addInstructorRow = () => {
    const { startDate, endDate } = this.props.eventFormStore.state.item;
    const emptyInstructorRow: EventInstructorViewModel = {
      location: undefined,
      period: { from: startDate, to: endDate },
      role: undefined,
      user: undefined,
      workingDays: undefined,
      workingHours: undefined,
      travelDays: undefined,
      noTravel: false
    };

    const instructors = [...this.props.eventFormStore.state.item.instructors, emptyInstructorRow];

    this.props.eventFormStore.change({ instructors });
  };

  private handleOnRemoveInstructor = (index: number) => {
    const instructors = this.props.eventFormStore.state.item.instructors.filter((_, i) => index !== i);
    const warningMessages = this.checkWarningMessages(instructors);
    this.props.eventFormStore.change({ instructors, warningMessages });
  };

  private handleIfInstructorUserHasValidated = (instructorId: string) => {
    const { eventFormStore } = this.props;
    const statusValidation = { ...eventFormStore.state.item.statusValidation };
    let {
      validatedByPlanners,
      validatedByInstructors,
      validatedByEmployees,
      validatedByPoC,
      validatedByFactoryLead,
      validatedByPlannersMTC
    } = statusValidation;

    if (!isNullOrWhiteSpaces(instructorId) && (validatedByEmployees || validatedByPlanners || validatedByPoC || validatedByPlannersMTC)) {
      if ((validatedByEmployees || []).length > 0 && validatedByEmployees.includes(instructorId))
        validatedByEmployees = validatedByEmployees.filter(userId => userId !== instructorId);

      if ((validatedByPlanners || []).length > 0 && validatedByPlanners.includes(instructorId))
        validatedByPlanners = validatedByPlanners.filter(userId => userId !== instructorId);

      if ((validatedByPlannersMTC || []).length > 0 && validatedByPlannersMTC.includes(instructorId))
        validatedByPlannersMTC = validatedByPlannersMTC.filter(userId => userId !== instructorId);

      if ((validatedByPoC || []).length > 0 && validatedByPoC.includes(instructorId))
        validatedByPoC = validatedByPoC.filter(userId => userId !== instructorId);

      if ((validatedByInstructors || []).length > 0 && validatedByInstructors.includes(instructorId))
        validatedByInstructors = validatedByInstructors.filter(userId => userId !== instructorId);

      if ((validatedByFactoryLead || []).length > 0 && validatedByFactoryLead.includes(instructorId))
        validatedByFactoryLead = validatedByFactoryLead.filter(userId => userId !== instructorId);
    }

    eventFormStore.change({ statusValidation });
  };

  private handleUnblockedForeignResourcesForPlannerTFTChanged(checked: boolean): void {
    const event = { ...this.props.eventFormStore.state.item };
    event.unblockedForeignResourcesForPlannerTFT = checked;

    this.changeUnblockButtonsStore.updateUnlockButtons({
      id: this.props.eventFormStore.state.item?.id,
      unblockedForeignResourcesForPlannerTFT: event.unblockedForeignResourcesForPlannerTFT,
      unblockedForeignResourcesForPlannerMTC: event.unblockedForeignResourcesForPlannerMTC
    });

    this.props.eventFormStore.change({ unblockedForeignResourcesForPlannerTFT: checked });
  }

  private handleUnblockedForeignResourcesForPlannerMTCChanged(checked: boolean): void {
    const event = { ...this.props.eventFormStore.state.item };
    event.unblockedForeignResourcesForPlannerMTC = checked;

    this.changeUnblockButtonsStore.updateUnlockButtons({
      id: this.props.eventFormStore.state.item?.id,
      unblockedForeignResourcesForPlannerTFT: event.unblockedForeignResourcesForPlannerTFT,
      unblockedForeignResourcesForPlannerMTC: event.unblockedForeignResourcesForPlannerMTC
    });

    this.props.eventFormStore.change({ unblockedForeignResourcesForPlannerMTC: checked });
  }

  componentDidUpdate(prevProps) {
    const { eventFormStore, isTabReadonly, readOnly } = this.props;
    const { state } = eventFormStore;
    if (!isTabReadonly && !readOnly && prevProps.isTabReadonly !== isTabReadonly && prevProps.readOnly !== readOnly) {
      this.getRecomendedInstructorsByExpertise();
    }
    if (!state.item) return;
    if (state.item.fromScheduler) return;
    const { startDate, endDate } = state.item;
    const start = DateTimeService.toMoment(startDate);
    const end = DateTimeService.toMoment(endDate);
    const notInEventDate = (state.item.instructors || []).any(({ period: { from, to } }, i) => {
      const fromDate = DateTimeService.toMoment(from);
      const toDate = DateTimeService.toMoment(to);
      return start.isAfter(fromDate, 'D') || end.isBefore(toDate, 'D');
    });
    if (notInEventDate) {
      const instructors = (state.item.instructors || []).map(instructor => {
        const period = { ...instructor.period };
        const fromDate = DateTimeService.toMoment(period.from);
        const toDate = DateTimeService.toMoment(period.to);

        if (start.isAfter(fromDate, 'D') || start.isAfter(toDate, 'D') || end.isBefore(toDate, 'D')) {
          if (start.isAfter(fromDate, 'D')) period.from = startDate;
          if (start.isAfter(toDate, 'D')) period.to = startDate;
          if (end.isBefore(toDate, 'D')) period.to = endDate;
          return { ...instructor, period };
        }
        return instructor;
      });
      eventFormStore.change({ instructors });
    }
  }

  handleTravelDays = (index: number, property: keyof TravelRangeDto, value: DateTimePeriod) => {
    const { instructors } = this.props.eventFormStore.state.item;
    const instructor = instructors[index];
    this.handleOnInstructorChange(index, 'travelDays', { ...instructor.travelDays, [property]: value });
  };

  handleNoTravel = (index: number, value: boolean) => {
    this.handleOnInstructorChange(index, 'noTravel', value);
  };

  private handleOnAddInstructorExpertise = () => {
    this.hideAddInstructorExpertiseModal();
  };

  private hideAddInstructorExpertiseModal = () => {
    this.setState({ showAddExpertiseModal: false, multipleInstructorsExpertise: [], instructorExpertise: null });
  };

  private isPlanner = () => {
    const userinfo = this.identityService.getUserInfo();
    return IdentityService.isPlanner(userinfo);
  };

  @autobind
  private onPlannerAssistantClosed() {
    this.setState({ modalPlannerAssistant: false });
    if (this.props.onClose) this.props.onClose(false);
  }

  private handlePlannerAssistantOnClick = () => {
    this.setState({ modalPlannerAssistant: true });
  };

  activeRoleCanSelectRowsForPlannerAssistant(): boolean {
    let currentUserInfo = this.identityService.getUserInfo();
    return IdentityService.isAdminOrPlanner(currentUserInfo);
  }

  public render() {
    const { t, isTabReadonly, instructorExpertiseStore, eventFormStore } = this.props;
    const {
      unblockedForeignResourcesForPlannerTFT,
      unblockedForeignResourcesForPlannerMTC,
      instructors,
      status
    } = eventFormStore.state.item;
    const {
      recomendedInstructorsByExpertise,
      intructorSelectorShown,
      canAddInstructor,
      loadingSelectedInstructorInfo,
      multipleInstructorsExpertise,
      instructorExpertise,
      showAddExpertiseModal,
      instructorExpertiseDefined
    } = this.state;
    const eventOwners = getEventOwners(eventFormStore.state.item.eventType);

    const isMachineRelated = eventFormStore.state.item.eventDetails ? eventFormStore.state.item.eventDetails.machineRelated : false;
    const machines = eventFormStore.state.item.eventDetails ? eventFormStore.state.item.eventDetails.machines : [];
    const eventTypeId = eventFormStore.state.item.eventType.id;
    const originalEventTypeId = eventFormStore.state.item.eventType.originalEventTypeId;
    const roleId = eventFormStore.state.item.eventDetails ? eventFormStore.state.item.eventDetails.profession.id : null;
    const trainingLevelId =
      eventFormStore.state.item.trainingDetails && eventFormStore.state.item.trainingDetails.trainingLevel
        ? eventFormStore.state.item.trainingDetails.trainingLevel.id
        : null;

    return (
      <>
        {(eventFormStore.state.isBusy || !canAddInstructor || loadingSelectedInstructorInfo) && (
          <div>
            <Dimmer active inverted>
              <Loader size="large" active inverted />
            </Dimmer>
          </div>
        )}
        <Form className="form__instructors-tab__container">
          <div className="form__instructors-tab__buttons-row">
            <FontAwesomeIcon className="form__header__row-icon" icon={faUserFriends} />

            <div className="instructors-tab__add-instructor-button">
              <Button
                disabled={isTabReadonly || !canAddInstructor}
                labelPosition={'right'}
                icon={'user plus'}
                content={t('Add instructor')}
                className="form__add-instructor-btn"
                onClick={this.addInstructorRow}
              />

              <Button
                disabled={isTabReadonly || !canAddInstructor}
                content={t('Show All Instructors')}
                className="form__show-all-instructor-btn"
                onClick={() => this.setState({ intructorSelectorShown: true })}
              />

              {!this.isPoc() && status.toString() === EventStatus[EventStatus.Draft] && (
                <Button
                  disabled={isTabReadonly || !canAddInstructor}
                  content={t('Planner Assistant')}
                  className="form__show-all-instructor-btn"
                  onClick={this.handlePlannerAssistantOnClick}
                />
              )}

              {(this.isEventOwner(eventOwners) || this.isAdmin() || this.isPlannerTFT() || this.isPlannerMTC()) &&
                eventOwners.any(x => x === 'PlannerMTC' || x === 'Planner') && (
                  <div className="instructors-tab-toggles__wrapper">
                    <label className="unblock-label">{t('Unblock resources')}</label>
                    {(this.isEventOwner(eventOwners) || this.isAdmin() || this.isPlannerMTC()) && eventOwners.any(x => x === 'Planner') && (
                      <div className="instructors-tab-toggles__items">
                        <label>{t('MTC')}</label>
                        <Checkbox
                          disabled={!this.isAdmin() && !(this.isPlannerMTC() && eventOwners.any(x => x === 'Planner'))}
                          className=""
                          toggle
                          checked={unblockedForeignResourcesForPlannerTFT ? unblockedForeignResourcesForPlannerTFT : false}
                          onChange={(_, { checked }) => this.handleUnblockedForeignResourcesForPlannerTFTChanged(checked)}
                        />
                      </div>
                    )}
                    {(this.isEventOwner(eventOwners) || this.isAdmin() || this.isPlannerTFT()) && eventOwners.any(x => x === 'PlannerMTC') && (
                      <div className="instructors-tab-toggles__items">
                        <label>{t('TFT')}</label>
                        <Checkbox
                          disabled={!this.isAdmin() && !(this.isPlannerTFT() && eventOwners.any(x => x === 'PlannerMTC'))}
                          className=""
                          toggle
                          checked={unblockedForeignResourcesForPlannerMTC ? unblockedForeignResourcesForPlannerMTC : false}
                          onChange={(_, { checked }) => this.handleUnblockedForeignResourcesForPlannerMTCChanged(checked)}
                        />
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>

          <Divider />

          {!instructorExpertiseStore.state.isBusy &&
            (instructors || []).map((instructor, index) => (
              <Fragment key={`instructor-${instructor.location?.title || 'undefined'}-${index}`}>
                <div className="form__event-details-tab__element">
                  <div className="instructors__deconste-icon__container">
                    {!isTabReadonly && (
                      <Icon
                        className="clickable-icon"
                        color="red"
                        name="remove"
                        size="large"
                        onClick={() => this.handleOnRemoveInstructor(index)}
                      />
                    )}
                  </div>
                  <div className="flex-start-center">
                    <p>{t("Instructor's Location")}</p>
                    <LocationEditor
                      readOnly={!instructor.user || this.isRecommendedInstructor(instructor) ? isTabReadonly : true}
                      className="planit-eventform-inputs"
                      value={instructor.location}
                      nullable
                      clearable
                      onChange={location => this.handleOnLocationChange(index, location)}
                    />
                  </div>

                  <div className="flex-start-center">
                    <p>{t("Instructor's Name")}</p>
                    {!instructor.user || this.isRecommendedInstructor(instructor) ? (
                      <EventInstructorsSelector
                        readOnly={isTabReadonly}
                        className="planit-eventform-inputs"
                        value={instructor.user?.id || null}
                        locationId={instructor.location?.id || null}
                        clearable
                        onChange={user => this.handleOnInstructorUserChange(index, user)}
                        options={recomendedInstructorsByExpertise}
                        onlyInstructorsPlannerTFT={this.isPlannerTFT() && !unblockedForeignResourcesForPlannerTFT}
                        onlyInstructorsPlannerMTC={this.isPlannerMTC() && !unblockedForeignResourcesForPlannerMTC}
                      />
                    ) : (
                      <EventInstructorsSelector
                        readOnly
                        className="planit-eventform-inputs"
                        value={instructor.user?.id || null}
                        options={[instructor?.user]}
                        onlyInstructorsPlannerTFT={this.isPlannerTFT() && !unblockedForeignResourcesForPlannerTFT}
                        onlyInstructorsPlannerMTC={this.isPlannerMTC() && !unblockedForeignResourcesForPlannerMTC}
                      />
                    )}
                  </div>

                  <div className="flex-start-center">
                    <p>{t('Role')}</p>
                    <EventInstructorRoleEditor
                      readOnly={isTabReadonly}
                      key={`roleeditor-${instructor.role ? instructor.role.toString() : 'undefined'}-${index}`}
                      className="planit-eventform-inputs"
                      value={instructor.role}
                      onChange={role => this.handleOnInstructorChange(index, 'role', role)}
                    />
                  </div>
                </div>

                <div className="form__event-details-tab__element form__instructors__second-row-styles">
                  <div className="flex-start-center">
                    <p>{t('Period Dates')}</p>
                    <div className="form__inputs-dates-wrapper">
                      <PeriodInput
                        id={'instructors-tab-pane-' + index}
                        readOnly={isTabReadonly}
                        value={instructor.period}
                        onChange={value => this.handleOnPeriodChange(index, value)}
                        min={instructor.period?.from}
                      />
                    </div>
                  </div>
                  {!isOfCategory(eventFormStore.state.item, EventTypeCategory.Vacations) && (
                    <div className="flex-start-center">
                      <p>{t('Working Days')}</p>
                      <Input
                        readOnly={!this.canEditWorkingTime(instructor)}
                        disabled={!this.canEditWorkingTime(instructor)}
                        className="form__field-numeric"
                        value={instructor.workingDays}
                        onChange={(e, { value }) => this.handleOnInstructorChange(index, 'workingDays', value)}
                      />
                    </div>
                  )}
                  {!isOfCategory(eventFormStore.state.item, EventTypeCategory.Vacations) && (
                    <div className="flex-start-center">
                      <p>{t('Working Hours')}</p>
                      <Input
                        readOnly={!this.canEditWorkingTime(instructor)}
                        disabled={!this.canEditWorkingTime(instructor)}
                        className="form__field-numeric"
                        value={instructor.workingHours}
                        onChange={(e, { value }) => this.handleOnInstructorChange(index, 'workingHours', value)}
                      />
                    </div>
                  )}
                  {status.toString() !== 'Draft' && (
                    <>
                      <div className="travel-days flex-start-center required">
                        <p>{t('Travel Days')}</p>
                        <div className="form__inputs-dates-wrapper">
                          <DatesRangeInput
                            className="travel-days__departure"
                            dontUseRedClearIconColor
                            iconPosition="right"
                            readOnly={!this.canEditWorkingDaysAndTravelDates(instructor)}
                            fromValue={instructor?.travelDays?.departure?.from}
                            toValue={instructor?.travelDays?.departure?.to}
                            onChange={(from, to) => this.handleTravelDays(index, 'departure', { from, to })}
                            placeholder={t('Departure')}
                            clearable
                            icon={false}
                            allowSameEndDate
                            getEveryChange
                          />
                          <DatesRangeInput
                            className="travel-days__arrival"
                            dontUseRedClearIconColor
                            iconPosition="right"
                            readOnly={!this.canEditWorkingDaysAndTravelDates(instructor)}
                            fromValue={instructor?.travelDays?.arrival?.from}
                            toValue={instructor?.travelDays?.arrival?.to}
                            onChange={(from, to) => this.handleTravelDays(index, 'arrival', { from, to })}
                            placeholder={t('Arrival')}
                            clearable
                            icon={false}
                            allowSameEndDate
                            getEveryChange
                          />
                        </div>
                      </div>
                      <div className="no-travel flex-start-center">
                        <p>{t('No Travel')}</p>
                        <Checkbox
                          readOnly={!this.canEditWorkingDaysAndNoTravel(instructor)}
                          checked={instructor?.noTravel}
                          onChange={(_, data) => this.handleNoTravel(index, data.checked)}
                        ></Checkbox>
                      </div>
                    </>
                  )}
                </div>
                <Divider />
              </Fragment>
            ))}

          {intructorSelectorShown && (
            <ChooseAllInstructorsView
              alreadySelected={instructors}
              onSelectInstructors={items => this.onSelectedInstructor(items)}
              onCloseModal={() => this.setState({ intructorSelectorShown: false })}
              onlyInstructorsPlannerTFT={this.isPlannerTFT() && !unblockedForeignResourcesForPlannerTFT}
              onlyInstructorsPlannerMTC={this.isPlannerMTC() && !unblockedForeignResourcesForPlannerMTC}
            />
          )}
          {(this.isAdmin() || this.isPlanner()) &&
            isMachineRelated &&
            showAddExpertiseModal &&
            ((instructorExpertise && !instructorExpertise.isPowerInstructor && !instructorExpertiseDefined) ||
              (multipleInstructorsExpertise || []).length > 0) && (
              <AddExpertisePopup
                machines={machines || []}
                open={showAddExpertiseModal}
                onSuccess={this.handleOnAddInstructorExpertise}
                onCancel={this.hideAddInstructorExpertiseModal}
                instructorIds={instructorExpertise || multipleInstructorsExpertise}
                eventTypeId={eventTypeId}
                originalEventTypeId={originalEventTypeId}
                roleId={roleId}
                trainingLevelId={trainingLevelId}
              />
            )}

          {this.state.modalPlannerAssistant && (
            <PlannerAssistant onClose={this.onPlannerAssistantClosed} selectedEventRows={[this.props.selectedEvent]} />
          )}
        </Form>
      </>
    );
  }
}

export default withTranslation()(InstructorTabPane);
