import * as autobind from 'autobind';
import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Container, Modal, Icon, Input, Header } from 'semantic-ui-react';
import { Query, OrderDefinition, DataModel } from 'stores/dataStore';
import { InstructorExpertiseDto, InstructorExpertiseStore } from 'stores/skills/instructor-expertise-store';
import { TableView, TableModel } from 'widgets/collections/table';
import { connect } from 'redux-scaffolding-ts';
import { EventInstructorRow } from 'site/pages/events/event-instructors';
import { UserStore } from 'stores/users/users-store';
import './choose-instructors-form.less';
import { PlanitPlannerTypes } from 'stores/configuration/profiles/pillars-store';
import { InstructorDto, InstructorStore } from 'stores/instructors/instructors-store';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import { nameof } from 'utils/object';
import { DataStoreFilter } from 'widgets/collections/table-filters/data-store-filter';
import { InstructorRoleDto, InstructorRolesStore } from 'stores/instructor-roles/instructor-roles-store';
import { isNullOrWhiteSpaces } from 'utils/useful-functions';
import { DropDownLocationsStore, LocationDto } from 'stores/configuration/locations/locations-store';
import { resolve } from 'inversify.config';
import { IdentityService } from 'services/identity-service';

interface ChooseAllInstructorsViewProps extends WithTranslation {
  onCloseModal?: () => void;
  alreadySelected?: EventInstructorRow[];
  instructorExpertisesStore?: InstructorExpertiseStore;
  onSelectInstructors?: (selectedInstructors: InstructorDto[]) => void;
  users?: UserStore;
  onlyInstructorsPlannerTFT?: boolean;
  onlyInstructorsPlannerMTC?: boolean;
  instructorStore?: InstructorStore;
  instructorRoles?: InstructorRolesStore;
  dropdownlocations?: DropDownLocationsStore;
}

interface ChooseInstructorsFilters {
  instructor: string;
  eventType: string;
  machineModel: string;
  role: string;
  training: string;
  subarea: string;
  location: string;
}

interface ChooseAllInstructorsViewState {
  query: Query;
  instructorExpertises: InstructorExpertiseDto[];
  selectedInstructors: InstructorDto[];
  filters: ChooseInstructorsFilters;
  filteredExpertises: DataModel<InstructorExpertiseDto>;
  activeFilters: string[];
  someFilterOpened: boolean;
}

@connect(
  ['dropdownlocations', DropDownLocationsStore],
  ['instructorRoles', InstructorRolesStore],
  ['instructorStore', InstructorStore],
  ['instructorExpertisesStore', InstructorExpertiseStore],
  ['users', UserStore]
)
class ChooseAllInstructorsView extends PureComponent<ChooseAllInstructorsViewProps, ChooseAllInstructorsViewState> {
  timer: any = null;

  @resolve(IdentityService)
  private identityService: IdentityService;

  constructor(props: ChooseAllInstructorsViewProps) {
    super(props);
    let activeFilters = [];
    this.state = {
      selectedInstructors: [],
      instructorExpertises: [],
      activeFilters,
      filters: {
        instructor: undefined,
        eventType: undefined,
        machineModel: undefined,
        role: undefined,
        training: undefined,
        subarea: undefined,
        location: undefined
      },
      query: {
        searchQuery: ``,
        orderBy: [],
        filter: [],
        skip: 0,
        take: 100000
      },
      filteredExpertises: undefined,
      someFilterOpened: false
    };
  }

  private get instructorsExpertiseStore() {
    return this.props.instructorExpertisesStore;
  }

  private get instructorStore() {
    return this.props.instructorStore;
  }

  componentDidMount = () => {
    this.load();
  };

  @autobind
  private onAddParticipants() {
    if (this.props.onSelectInstructors) this.props.onSelectInstructors(this.state.selectedInstructors);

    if (this.props.onCloseModal) this.props.onCloseModal();
  }

  @autobind
  private onCancel() {
    if (this.props.onCloseModal) this.props.onCloseModal();
  }

  @autobind
  private load() {
    //this.instructorsExpertiseStore.getAllAsync(this.state.query, true, this.state.filters.instructor, this.state.filters.location);
    this.instructorStore.getAllAsync(this.state.query, true);
  }

  @autobind
  private handleOrderBy(orderBy: OrderDefinition[]) {
    this.setState({ query: Object.assign(this.state.query, { orderBy }) }, this.load);
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: { ...this.state.query, skip, take } }, () => this.load());
  }

  // @autobind
  // private handleFilterChange(filters, oDatafilters) {
  //   const query = { ...this.state.query, filter: oDatafilters, skip: 0 };
  //   this.setState({ filters, query }, () => this.load());
  // }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.filter(x => x.id !== 'location').map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    let parameters = {};
    if (filter.length !== filters.length) {
      parameters['instructorLocation'] = filters.first(x => x.id === 'location').filter;
    }

    const query = Object.assign(this.state.query, { filter: [...filter, { Enabled: { eq: true } }], skip: 0, parameters });
    this.setState({ query, activeFilters }, () => this.load());
  }

  //@autobind
  // private handleOnInstructorNameFilterChanged = (e, { value }) => {
  //   clearTimeout(this.timer);

  //   this.timer = setTimeout(() => {
  //     let filters = { ...this.state.filters };
  //     filters.instructor = value;
  //     this.refreshTable(filters);
  //   }, 1000);
  // };

  // private handleFilterByMachineModel(machineModel: ItemReference) {
  //   let filters = { ...this.state.filters };
  //   filters.machineModel = machineModel ? machineModel.id : null;
  //   this.refreshTable(filters);
  // }

  // private handleFilterByEventType(eventType: ItemReference) {
  //   let filters = { ...this.state.filters };
  //   filters.eventType = eventType ? eventType.id : null;
  //   this.refreshTable(filters);
  // }
  // private handleFilterByTraining(training: ItemReference) {
  //   let filters = { ...this.state.filters };
  //   filters.training = training ? training.id : null;
  //   this.refreshTable(filters);
  // }

  // private handleFilterByRole(role: ItemReference) {
  //   let filters = { ...this.state.filters };
  //   filters.role = role ? role.id : null;
  //   this.refreshTable(filters);
  // }
  // private handleFunctionalSubArea(funcionalsubArea: ItemReference) {
  //   let filters = { ...this.state.filters };
  //   filters.subarea = funcionalsubArea ? funcionalsubArea.id : null;
  //   this.refreshTable(filters);
  // }

  // private handleFilterByLocation(location: ItemReference) {
  //   let filters = { ...this.state.filters };
  //   filters.location = location ? location.id : null;
  //   this.refreshTable(filters);
  // }

  // private refreshTable(filters: ChooseInstructorsFilters) {
  //   const oDataFilters = this.buildODataFilter(filters);
  //   this.handleFilterChange(filters, oDataFilters);
  // }

  // private buildODataFilter(filters: ChooseInstructorsFilters) {
  //   // Pending Gino
  //   // if (filters.instructor) {
  //   //   filterExpertises = filterExpertises.filter(x => x.instructor.includes(filters.instructor));
  //   // }

  //   // Pending Gino
  //   // if (filters.location) {
  //   //   filterExpertises = filterExpertises.filter(x =>
  //   //     x.user.roles.any(r => (r.location ? r.location.id : '') === filters.location && (r.role ? r.role.name : '') === 'Instructor')
  //   //   );
  //   // }

  //   let oDataFilter = [];

  //   if (filters.eventType) {
  //     oDataFilter.push({ EventTypeId: { eq: { type: 'guid', value: filters.eventType } } });
  //   }

  //   if (filters.role) {
  //     oDataFilter.push({ RoleId: { eq: { type: 'guid', value: filters.role } } });
  //   }

  //   if (filters.training) {
  //     oDataFilter.push({ TrainingLevelId: { eq: { type: 'guid', value: filters.training } } });
  //   }

  //   if (filters.subarea) {
  //     oDataFilter.push({ NMRFunctionalSubAreaId: { eq: { type: 'guid', value: filters.subarea } } });
  //   }

  //   if (filters.machineModel) {
  //     oDataFilter.push({ MachineModelId: { eq: { type: 'guid', value: filters.machineModel } } });
  //   }

  //   return oDataFilter;
  // }

  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters, someFilterOpened } = this.state;
    const currentUserInfo = this.identityService.getUserInfo();
    const areYouPoc = IdentityService.isPoc(currentUserInfo);
    const pocLocations = currentUserInfo.locationsByRoles['PoC'] as string[];

    let isRowDisabled: (item: InstructorExpertiseDto) => boolean = undefined;

    if (!!this.props.onlyInstructorsPlannerTFT || !!this.props.onlyInstructorsPlannerMTC) {
      if (this.props.onlyInstructorsPlannerTFT) {
        isRowDisabled = item =>
          !(item.user.pillar && (item.user.pillar.managedBy === PlanitPlannerTypes.plannerTFT || !item.user.pillar.managedBy));
      }

      if (this.props.onlyInstructorsPlannerMTC) {
        isRowDisabled = item =>
          !(item.user.pillar && (item.user.pillar.managedBy === PlanitPlannerTypes.plannerMTC || !item.user.pillar.managedBy));
      }
    }

    const tableModel = {
      columns: [
        {
          title: t('First Name'),
          tooltipRenderer: true,
          renderer: data => <span>{data.name}</span>,
          editor: (data, onChange) => (
            <Input
              value={data.name}
              fluid
              onChange={(e, { value }) => {
                data.name = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Name')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<InstructorDto>('name'), `startswith(tolower(name), '${value.toLowerCase()}')`)}
              onClear={() => onClear(nameof<InstructorDto>('name'))}
              active={activeFilters.includes(nameof<InstructorDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<InstructorDto>('name'),
            useProfile: false
          }
        },
        {
          title: t('Last Name'),
          tooltipRenderer: true,
          renderer: data => data.surname,
          editor: (data, onChange) => (
            <Input
              value={data.surname}
              fluid
              onChange={(e, { value }) => {
                data.surname = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Last Name')}
              triggerTitle={title}
              onFilter={value => onFilter(nameof<InstructorDto>('surname'), `startswith(tolower(surname), '${value.toLowerCase()}')`)}
              onClear={() => onClear(nameof<InstructorDto>('surname'))}
              active={activeFilters.includes(nameof<InstructorDto>('surname'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<InstructorDto>('surname'),
            useProfile: false
          }
        },
        {
          title: t("Instructor's Role"),
          renderer: data => <span>{data.instructorRole ? data.instructorRole.name : ''}</span>,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DataStoreFilter<InstructorRoleDto>
              filterTitle={t("Instructor's Role")}
              triggerTitle={title}
              onFilter={(value: string) => onFilter('instructorRole', { InstructorRoleId: { eq: { value, type: 'guid' } } })}
              onClear={() => onClear('instructorRole')}
              active={activeFilters.includes('instructorRole')}
              getItems={q => this.props.instructorRoles.getAllAsync(q)}
              parameters=""
              orderBy={[{ direction: 'Ascending', field: nameof<InstructorRoleDto>('name'), useProfile: false }]}
              filterGenerator={search => (isNullOrWhiteSpaces(search) ? {} : { 'tolower(name)': { startswith: search.toLowerCase() } })}
              valueSelector={(l: InstructorRoleDto) => l.id}
              titleSelector={(l: InstructorRoleDto) => l.name}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Location'),
          renderer: data => (data.location ? data.location.location : ''),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DataStoreFilter<LocationDto>
              filterTitle={t('Filter by Location')}
              triggerTitle={title}
              onFilter={(value: string) => onFilter(nameof<InstructorDto>('location'), value)}
              onClear={() => onClear(nameof<LocationDto>('location'))}
              active={activeFilters.includes(nameof<LocationDto>('location'))}
              getItems={q => this.props.dropdownlocations.getAllAsync(q)}
              parameters="id,location"
              orderBy={[{ direction: 'Ascending', field: nameof<LocationDto>('location'), useProfile: false }]}
              filterGenerator={search => (isNullOrWhiteSpaces(search) ? {} : { 'tolower(location)': { startswith: search.toLowerCase() } })}
              valueSelector={(l: LocationDto) => l.id}
              titleSelector={(l: LocationDto) => l.location}
              preFilteredIds={areYouPoc ? pocLocations : null}
              onActivate={this.handleOnActivateFilter}
            />
          )
        }
      ],
      data: this.props.instructorStore.state
    } as TableModel<InstructorDto>;

    return (
      <Modal
        className="modal-transparent choose-instructors__modal"
        open
        closeOnEscape={true}
        onClose={this.onCancel}
        onCloseUsersModal={this.onCancel}
        closeOnDimmerClick={false}
      >
        <Modal.Header className="search__modal__header">
          <Header as="h2" className="modal-header-title">
            {t('Instructor Search')}
          </Header>
        </Modal.Header>
        <Modal.Content className="modal-content">
          <Container className="student-list-table choose-instructor-form__table-wrapper">
            <TableView
              isRowDisabled={isRowDisabled}
              model={tableModel}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onPageChange={this.handlePageChange}
              selectable={true}
              onSelectionChange={users => this.selectedInsctructor(users)}
              unselectFirstRow
              preventEnterKeyDownEvent={someFilterOpened}
              onFilterChange={this.handleFilterChange}
            ></TableView>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button className="basic" onClick={this.onCancel}>
            {t('Cancel')}
          </Button>
          <Button className="add-participants-button" onClick={this.onAddParticipants} positive>
            {t('Add Instructor')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  selectedInsctructor(items: unknown[]): void {
    this.setState({ selectedInstructors: items as any });
  }

  renderSelectedIcon(isSelected: boolean) {
    if (!isSelected) return <></>;

    return <Icon name="check" color="black" />;
  }
}

// Wire up the React component to the Redux store
export default withTranslation()(ChooseAllInstructorsView);
