import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Message, Icon, Grid, Input, Checkbox } from 'semantic-ui-react';
import { RestrictionWarningDto, RestrictionWarningsStore } from 'stores/configuration/planner-assistant/restrictions-warnings-store';
import { OrderDefinition, Query } from 'stores/dataStore';
import { TableModel, TableView } from 'widgets/collections/table';
import NewRestrictionWarningView from './new-restriction-warning';
import ChangeRestrictionWarningView from './edit-restriction-warning';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import { nameof } from 'utils/object';
import { CheckboxFilter } from 'widgets/collections/table-filters/checkbox-filter';

export interface RestrictionWarningListProps extends WithTranslation, RouteComponentProps {
  restrictionWarnings: RestrictionWarningsStore;
}

export interface RestrictionWarningListState {
  query: Query;
  newRestrictionWarningShown: boolean;
  changeRestrictionWarningShown: boolean;
  activeFilters: string[];
  selectedItem: any;
}

@connect(['restrictionWarnings', RestrictionWarningsStore])
class RestrictionWarningListPage extends React.Component<RestrictionWarningListProps, RestrictionWarningListState> {
  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'createdOn', useProfile: false }], skip: 0, take: 10 },
      newRestrictionWarningShown: false,
      changeRestrictionWarningShown: false,
      activeFilters: [],
      selectedItem: null
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.restrictionWarnings.getAllAsync(this.state.query);
  }

  @autobind
  private onNewItem() {
    this.setState({ newRestrictionWarningShown: true });
  }

  @autobind
  private onNewItemClosed(isSuccess: boolean) {
    this.setState({ newRestrictionWarningShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private onEditItem() {
    this.setState({ changeRestrictionWarningShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeRestrictionWarningShown: false });
    if (isSuccess) this.load();
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  private handleFilterChange = (filters: { id: string; filter: any }[]) => {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  };

  private handleOrderBy = (newOrder: OrderDefinition[]) => {
    const orderBy: OrderDefinition[] = [...newOrder, { direction: 'Descending', field: 'modifiedOn', useProfile: false }];
    this.setState(({ query }) => ({ query: { ...query, orderBy } }), this.load);
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('TTC Location'),
          renderer: data => <span>{data.ttcLocationName}</span>,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.ttcLocationName}
              fluid
              onChange={(e, { value }) => {
                data.ttcLocationName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by TTC Location')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<RestrictionWarningDto>('ttcLocationName'),
                  `contains(tolower(${nameof<RestrictionWarningDto>('ttcLocationName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<RestrictionWarningDto>('ttcLocationName'))}
              active={activeFilters.includes(nameof<RestrictionWarningDto>('ttcLocationName'))}
            />
          ),
          sortDefinition: { field: nameof<RestrictionWarningDto>('ttcLocationName'), useProfile: false }
        },
        {
          title: t('Traveling Location'),
          renderer: data => <span>{data.travelingLocationName}</span>,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.travelingLocationName}
              fluid
              onChange={(e, { value }) => {
                data.travelingLocationName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Traveling Location')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<RestrictionWarningDto>('travelingLocationName'),
                  `contains(tolower(${nameof<RestrictionWarningDto>('travelingLocationName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<RestrictionWarningDto>('travelingLocationName'))}
              active={activeFilters.includes(nameof<RestrictionWarningDto>('travelingLocationName'))}
            />
          ),
          sortDefinition: { field: nameof<RestrictionWarningDto>('travelingLocationName'), useProfile: false }
        },
        {
          title: t('Visa Requirement'),
          renderer: data => <span>{data.visaName}</span>,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.visaName}
              fluid
              onChange={(e, { value }) => {
                data.visaName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Visa')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<RestrictionWarningDto>('visaName'),
                  `contains(tolower(${nameof<RestrictionWarningDto>('visaName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<RestrictionWarningDto>('visaName'))}
              active={activeFilters.includes(nameof<RestrictionWarningDto>('visaName'))}
            />
          ),
          sortDefinition: { field: nameof<RestrictionWarningDto>('visaName'), useProfile: false }
        },
        {
          title: t('Visa application. Months duration'),
          tooltipRenderer: false,
          renderer: data => <span>{data.visaApplicationDuration}</span>
        },
        {
          title: t('Restriction'),
          tooltipRenderer: false,
          renderer: data => <span>{!!data.isRestriction ? t('Yes') : t('No')}</span>,
          editor: (data, onChange) => (
            <Checkbox
              toggle
              checked={data.isRestriction}
              onChange={(e, { checked }) => {
                data.isRestriction = !!checked;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <CheckboxFilter
              filterTitle={t('Filter by Restriction')}
              trueLabel={t('Yes')}
              falseLabel={t('No')}
              triggerTitle={title}
              onFilter={(value: boolean) => onFilter(nameof<RestrictionWarningDto>('isRestriction'), { isRestriction: value })}
              onClear={() => onClear(nameof<RestrictionWarningDto>('isRestriction'))}
              active={activeFilters.includes(nameof<RestrictionWarningDto>('isRestriction'))}
            />
          ),
          sortDefinition: { field: nameof<RestrictionWarningDto>('isRestriction'), useProfile: false }
        },
        {
          title: t('Comments'),
          tooltipRenderer: false,
          renderer: data => <span>{data.comments}</span>
        },
        {
          title: t('Contract'),
          renderer: data => <span>{data.contractName}</span>,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.contractName}
              fluid
              onChange={(e, { value }) => {
                data.contractName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Contract')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<RestrictionWarningDto>('contractName'),
                  `contains(tolower(${nameof<RestrictionWarningDto>('contractName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<RestrictionWarningDto>('contractName'))}
              active={activeFilters.includes(nameof<RestrictionWarningDto>('contractName'))}
            />
          ),
          sortDefinition: { field: nameof<RestrictionWarningDto>('contractName'), useProfile: false }
        },
        {
          title: t('Flight Cost'),
          renderer: data => <span>{data.flightCostName}</span>,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.flightCostName}
              fluid
              onChange={(e, { value }) => {
                data.flightCostName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Flight Cost')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<RestrictionWarningDto>('flightCostName'),
                  `contains(tolower(${nameof<RestrictionWarningDto>('flightCostName')}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(nameof<RestrictionWarningDto>('flightCostName'))}
              active={activeFilters.includes(nameof<RestrictionWarningDto>('flightCostName'))}
            />
          ),
          sortDefinition: { field: nameof<RestrictionWarningDto>('flightCostName'), useProfile: false }
        }
      ],
      data: this.props.restrictionWarnings.state
    } as TableModel<RestrictionWarningDto>;

    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.restrictionWarnings.state.result && !this.props.restrictionWarnings.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.restrictionWarnings.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}

          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              selectable={false}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="pencil alternate" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, this.onEditItem);
                  }
                }
              ]}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onPageChange={this.handlePageChange}
              canCreateNew={true}
              onNewItem={this.onNewItem}
              createNewButtonTitle={t('New Restriction')}
              onFilterChange={this.handleFilterChange}
              onOrderByChanged={this.handleOrderBy}
            ></TableView>
          </Grid.Row>
        </Grid>
        {(this.state.newRestrictionWarningShown && <NewRestrictionWarningView onClose={this.onNewItemClosed} {...this.props} />) ||
          (this.state.changeRestrictionWarningShown && (
            <ChangeRestrictionWarningView onClose={this.onEditItemClosed} currentRestrictionWarning={this.state.selectedItem} />
          ))}
      </>
    );
  }
}

export default withTranslation()(RestrictionWarningListPage);
