import { BaseDto } from '../../types';
import { AbstractValidator } from 'fluent-ts-validator';
import i18n from '../../../i18n';
import { repository } from 'redux-scaffolding-ts';
import { DataStore } from '../../dataStore';
import { FormStore } from '../../formStore';

export interface RestrictionWarningDto extends BaseDto {
  id: string;
  ttcLocationId: string;
  ttcLocationName: string;
  travelingLocationId: string;
  travelingLocationName: string;
  visaId: string;
  visaName: string;
  visaApplicationDuration: number;
  isRestriction: boolean;
  comments: string;
  contractId: string;
  contractName: string;
  flightCostId: string;
  flightCostName: string;
}

export interface CreateRestrictionWarningDto {
  TTCLocationId: string;
  TravelingLocationId: string;
  VisaId: string;
  VisaApplicationDuration: number;
  IsRestriction: boolean;
  Comments: string;
  ContractId: string;
  FlightCostId: string;
}

export interface ChangeRestrictionWarningDto {
  Id: string;
  TTCLocationId: string;
  TravelingLocationId: string;
  VisaId: string;
  VisaApplicationDuration: number;
  IsRestriction: boolean;
  Comments: string;
  ContractId: string;
  FlightCostId: string;
}

export class RestrictionWarningValidator extends AbstractValidator<RestrictionWarningDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Id is required'));

    this.validateIfString(o => o.ttcLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('TTC Location is required'));

    this.validateIfString(o => o.travelingLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Traveling Location is required'));

    this.validateIfString(o => o.visaId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Visa Requirement is required'));

    this.validateIfNumber(o => o.visaApplicationDuration)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Visa application. Months duration is required'));

    this.validateIfNumber(o => o.visaApplicationDuration)
      .isDefined()
      .isNotNull()
      .isGreaterThanOrEqual(0)
      .withFailureMessage(i18n.t('Visa application. Months duration is lower than 0 months'));

    this.validateIfString(o => o.comments)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Comments is required'));

    this.validateIfString(o => o.contractId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Contract is required'));

    this.validateIfString(o => o.flightCostId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Flight Cost is required'));
  }
}

export class CreateRestrictionWarningValidator extends AbstractValidator<CreateRestrictionWarningDto> {
  constructor() {
    super();

    this.validateIfString(o => o.TTCLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('TTC Location is required'));

    this.validateIfString(o => o.TravelingLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Traveling Location is required'));

    this.validateIfString(o => o.VisaId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Visa Requirement is required'));

    this.validateIfNumber(o => o.VisaApplicationDuration)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Visa application. Months duration is required'));

    this.validateIfNumber(o => o.VisaApplicationDuration)
      .isDefined()
      .isNotNull()
      .isGreaterThanOrEqual(0)
      .withFailureMessage(i18n.t('Visa application. Months duration is lower than 0 months'));

    this.validateIfString(o => o.Comments)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Comments is required'));

    this.validateIfString(o => o.ContractId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Contract is required'));

    this.validateIfString(o => o.FlightCostId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Flight Cost is required'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(o => o.TTCLocationId && o.TravelingLocationId && o.TTCLocationId !== o.TravelingLocationId)
      .withFailureMessage(i18n.t('TTC and Traveling locations must be differents'));
  }
}

export class ChangeRestrictionWarningValidator extends AbstractValidator<ChangeRestrictionWarningDto> {
  constructor() {
    super();

    this.validateIfString(o => o.Id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Id is required'));

    this.validateIfString(o => o.TTCLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('TTC Location is required'));

    this.validateIfString(o => o.TravelingLocationId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Traveling Location is required'));

    this.validateIfString(o => o.VisaId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Visa Requirement is required'));

    this.validateIfNumber(o => o.VisaApplicationDuration)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Visa application. Months duration is required'));

    this.validateIfNumber(o => o.VisaApplicationDuration)
      .isDefined()
      .isNotNull()
      .isGreaterThanOrEqual(0)
      .withFailureMessage(i18n.t('Visa application. Months duration is lower than 0 months'));

    this.validateIfString(o => o.Comments)
      .isDefined()
      .isNotNull()
      .isNotEmpty()
      .withFailureMessage(i18n.t('Comments is required'));

    this.validateIfString(o => o.ContractId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Contract is required'));

    this.validateIfString(o => o.FlightCostId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Flight Cost is required'));

    this.validateIf(o => o)
      .isNotNull()
      .fulfills(o => o.TTCLocationId && o.TravelingLocationId && o.TTCLocationId !== o.TravelingLocationId)
      .withFailureMessage(i18n.t('TTC and Traveling locations must be differents'));
  }
}

@repository('@@RESTRICTIONSWARNINGS', 'restrictionWarnings.summary')
export class RestrictionWarningsStore extends DataStore<RestrictionWarningDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-restriction';
  retrievePath = 'get-restrictions';
  updatePath = 'update-restriction';
  deletePath = 'delete-restriction';

  protected validate(item: RestrictionWarningDto) {
    return new RestrictionWarningValidator().validate(item);
  }

  constructor() {
    super('RESTRICTIONWARNING', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }
}

@repository('@@RESTRICTIONSWARNINGS', 'restrictionWarnings.new')
export class NewRestrictionWarningStore extends FormStore<CreateRestrictionWarningDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-restriction';
  retrievePath = 'get-restrictions';
  updatePath = 'update-restriction';

  protected validate(item: CreateRestrictionWarningDto) {
    return new CreateRestrictionWarningValidator().validate(item);
  }

  constructor() {
    super('NEW_RESTRICTIONWARNING', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@RESTRICTIONSWARNINGS', 'restrictionWarning.change')
export class ChangeRestrictionWarningStore extends FormStore<ChangeRestrictionWarningDto> {
  baseUrl = 'master-data/v1';
  createPath = 'new-restriction';
  retrievePath = 'get-restrictions';
  updatePath = 'update-restriction';

  protected validate(item: ChangeRestrictionWarningDto) {
    return new ChangeRestrictionWarningValidator().validate(item);
  }

  constructor() {
    super('CHANGE_RESTRICTIONWARNING', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}
