import * as autobind from 'autobind';
import moment from 'moment';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Message, Icon, Grid, Input } from 'semantic-ui-react';
import {
  InstructorVisaRestrictionDto,
  InstructorVisaRestrictionStore
} from 'stores/configuration/planner-assistant/instructor-visa-restriction-store';
import { RestrictionWarningDto } from 'stores/configuration/planner-assistant/restrictions-warnings-store';
import { OrderDefinition, Query } from 'stores/dataStore';
import { InstructorVisaDto } from 'stores/skills/instructor-visa-store';
import { nameof } from 'utils/object';
import { TableModel, TableView } from 'widgets/collections/table';
import { TextBoxFilter } from 'widgets/collections/table-filters/textbox-filter';
import ChangeInstructorVisaRestrictionView from './edit-instructor-visa-restriction';

export interface InstructorVisaRestrictionListProps extends WithTranslation, RouteComponentProps {
  instructorVisaRestriction: InstructorVisaRestrictionStore;
}

export interface InstructorVisaRestrictionListState {
  query: Query;
  changeInstructorVisaRestrictionShown: boolean;
  activeFilters: string[];
  selectedItem: any;
}

@connect(['instructorVisaRestriction', InstructorVisaRestrictionStore])
class InstructorVisaRestrictionListPage extends React.Component<InstructorVisaRestrictionListProps, InstructorVisaRestrictionListState> {
  constructor(props) {
    super(props);
    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'createdOn', useProfile: false }], skip: 0, take: 10 },
      changeInstructorVisaRestrictionShown: false,
      activeFilters: [],
      selectedItem: null
    };
  }

  componentDidMount() {
    this.load();
  }

  @autobind
  private load() {
    this.props.instructorVisaRestriction.getAllAsync(this.state.query);
  }

  @autobind
  private onEditItem() {
    this.setState({ changeInstructorVisaRestrictionShown: true });
  }

  @autobind
  private onEditItemClosed(isSuccess: boolean) {
    this.setState({ changeInstructorVisaRestrictionShown: false });
    if (isSuccess) this.load();
  }

  private getDateWithOutTimeZone = (date: Date) => {
    const myFormat = 'YYYY-MM-DD';
    return moment(date, 'YYYYMMDDTHHmmss')
      .format(myFormat)
      .split('-');
  };

  @autobind
  private handlePageChange(skip: number, take: number) {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, () => {
      this.load();
    });
  }

  private handleFilterChange = (filters: { id: string; filter: any }[]) => {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  };

  private handleOrderBy = (newOrder: OrderDefinition[]) => {
    const orderBy: OrderDefinition[] = [...newOrder, { direction: 'Descending', field: 'createdOn', useProfile: false }];
    this.setState(({ query }) => ({ query: { ...query, orderBy } }), this.load);
  };

  private handleIsValidVisa = (item: InstructorVisaRestrictionDto) => {
    if (!item.instructorVisa.startDate || !item.instructorVisa.expireDate) return false;
    const currentDate: Date = new Date();
    const dateFromISO: Date = new Date(item.instructorVisa.startDate);
    const dateToISO: Date = new Date(item.instructorVisa.expireDate);
    return dateFromISO <= currentDate && dateToISO > currentDate;
  };

  public render() {
    const { t } = this.props as any;
    const { activeFilters } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Instructor'),
          renderer: data => <span>{data.instructorVisa.instructor}</span>,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.instructorVisa.instructor}
              fluid
              onChange={(e, { value }) => {
                data.instructorVisa.instructor = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Instructor')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  `${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('instructor')}`,
                  `contains(tolower(${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>(
                    'instructor'
                  )}), '${value.toLowerCase()}')`
                )
              }
              onClear={() =>
                onClear(`${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('instructor')}`)
              }
              active={activeFilters.includes(
                `${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('instructor')}`
              )}
            />
          ),
          sortDefinition: {
            field: `${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('instructor')}`,
            useProfile: false
          }
        },
        {
          title: t('TTC Location'),
          renderer: data => <span>{data.instructorVisa.ttcLocationName}</span>,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.instructorVisa.ttcLocationName}
              fluid
              onChange={(e, { value }) => {
                data.instructorVisa.ttcLocationName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by TTC Location')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  `${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('ttcLocationName')}`,
                  `contains(tolower(${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>(
                    'ttcLocationName'
                  )}), '${value.toLowerCase()}')`
                )
              }
              onClear={() =>
                onClear(`${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('ttcLocationName')}`)
              }
              active={activeFilters.includes(
                `${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('ttcLocationName')}`
              )}
            />
          ),
          sortDefinition: {
            field: `${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('ttcLocationName')}`,
            useProfile: false
          }
        },
        {
          title: t('Traveling Location'),
          renderer: data => <span>{data.instructorVisa.travelingLocationName}</span>,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.instructorVisa.travelingLocationName}
              fluid
              onChange={(e, { value }) => {
                data.instructorVisa.travelingLocationName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Traveling Location')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  `${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('travelingLocationName')}`,
                  `contains(tolower(${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>(
                    'travelingLocationName'
                  )}), '${value.toLowerCase()}')`
                )
              }
              onClear={() =>
                onClear(`${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('travelingLocationName')}`)
              }
              active={activeFilters.includes(
                `${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('travelingLocationName')}`
              )}
            />
          ),
          sortDefinition: {
            field: `${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('travelingLocationName')}`,
            useProfile: false
          }
        },
        {
          title: t('Start Date'),
          renderer: data => {
            if (data.instructorVisa.startDate) {
              const date = this.getDateWithOutTimeZone(new Date(data.instructorVisa.startDate));
              const printableDate = `${date[2]}/${date[1]}/${date[0]}`.trim();
              return <span>{printableDate}</span>;
            }
          },
          tooltipRenderer: true,
          sortDefinition: {
            field: `${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('startDate')}`,
            useProfile: false
          }
        },
        {
          title: t('Expire Date'),
          renderer: data => {
            if (data.instructorVisa.startDate) {
              const date = this.getDateWithOutTimeZone(new Date(data.instructorVisa.expireDate));
              const printableDate = `${date[2]}/${date[1]}/${date[0]}`.trim();
              return <span>{printableDate}</span>;
            }
          },
          tooltipRenderer: true,
          sortDefinition: {
            field: `${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('expireDate')}`,
            useProfile: false
          }
        },
        {
          title: t('Instructor Restriction'),
          tooltipRenderer: false,
          renderer: data => <span>{!!data.instructorVisa.isInstructorRestriction ? t('Yes') : t('No')}</span>
        },
        {
          title: t('Instructor Comments'),
          tooltipRenderer: false,
          renderer: data => <span>{data.instructorVisa.instructorComments}</span>
        },
        {
          title: t('Visa application. Months duration'),
          tooltipRenderer: false,
          renderer: data => <span>{data.restriction.visaApplicationDuration}</span>
        },
        {
          title: t('Visa Requirement'),
          renderer: data => <span>{data.instructorVisa.visaName}</span>,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.instructorVisa.visaName}
              fluid
              onChange={(e, { value }) => {
                data.instructorVisa.visaName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Visa')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  `${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('visaName')}`,
                  `contains(tolower(${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>(
                    'visaName'
                  )}), '${value.toLowerCase()}')`
                )
              }
              onClear={() => onClear(`${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('visaName')}`)}
              active={activeFilters.includes(
                `${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('visaName')}`
              )}
            />
          ),
          sortDefinition: {
            field: `${nameof<InstructorVisaRestrictionDto>('instructorVisa')}/${nameof<InstructorVisaDto>('visaName')}`,
            useProfile: false
          }
        },
        {
          title: t('Visa Restriction'),
          tooltipRenderer: false,
          renderer: data => <span>{!!data.restriction.isRestriction ? t('Yes') : t('No')}</span>
        },
        {
          title: t('Restriction Comments'),
          tooltipRenderer: false,
          renderer: data => <span>{data.restriction.comments}</span>
        },
        {
          title: t('Contract'),
          renderer: data => <span>{data.restriction.contractName}</span>,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.restriction.contractName}
              fluid
              onChange={(e, { value }) => {
                data.restriction.contractName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Visa')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  `${nameof<InstructorVisaRestrictionDto>('restriction')}/${nameof<RestrictionWarningDto>('contractName')}`,
                  `contains(tolower(${nameof<InstructorVisaRestrictionDto>('restriction')}/${nameof<RestrictionWarningDto>(
                    'contractName'
                  )}), '${value.toLowerCase()}')`
                )
              }
              onClear={() =>
                onClear(`${nameof<InstructorVisaRestrictionDto>('restriction')}/${nameof<RestrictionWarningDto>('contractName')}`)
              }
              active={activeFilters.includes(
                `${nameof<InstructorVisaRestrictionDto>('restriction')}/${nameof<RestrictionWarningDto>('contractName')}`
              )}
            />
          ),
          sortDefinition: {
            field: `${nameof<InstructorVisaRestrictionDto>('restriction')}/${nameof<RestrictionWarningDto>('contractName')}`,
            useProfile: false
          }
        },
        {
          title: t('Flight Cost'),
          renderer: data => <span>{data.restriction.flightCostName}</span>,
          tooltipRenderer: true,
          editor: (data, onChange) => (
            <Input
              value={data.restriction.flightCostName}
              fluid
              onChange={(e, { value }) => {
                data.restriction.flightCostName = value;
                onChange();
              }}
            />
          ),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Visa')}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  `${nameof<InstructorVisaRestrictionDto>('restriction')}/${nameof<RestrictionWarningDto>('flightCostName')}`,
                  `contains(tolower(${nameof<InstructorVisaRestrictionDto>('restriction')}/${nameof<RestrictionWarningDto>(
                    'flightCostName'
                  )}), '${value.toLowerCase()}')`
                )
              }
              onClear={() =>
                onClear(`${nameof<InstructorVisaRestrictionDto>('restriction')}/${nameof<RestrictionWarningDto>('flightCostName')}`)
              }
              active={activeFilters.includes(
                `${nameof<InstructorVisaRestrictionDto>('restriction')}/${nameof<RestrictionWarningDto>('flightCostName')}`
              )}
            />
          ),
          sortDefinition: {
            field: `${nameof<InstructorVisaRestrictionDto>('restriction')}/${nameof<RestrictionWarningDto>('flightCostName')}`,
            useProfile: false
          }
        }
      ],
      data: this.props.instructorVisaRestriction.state
    } as TableModel<InstructorVisaRestrictionDto>;

    return (
      <>
        <Grid className="event-types-list-grid">
          {this.props.instructorVisaRestriction.state.result && !this.props.instructorVisaRestriction.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.instructorVisaRestriction.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}

          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              selectable={false}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              model={tableModel}
              extraActions={[
                {
                  content: (
                    <>
                      <Icon name="pencil alternate" />
                      &nbsp;{t('Edit')}
                    </>
                  ),
                  onClick: item => {
                    this.setState({ selectedItem: item }, this.onEditItem);
                  }
                }
              ]}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              onOrderByChanged={this.handleOrderBy}
            ></TableView>
          </Grid.Row>
        </Grid>
        {this.state.changeInstructorVisaRestrictionShown && (
          <ChangeInstructorVisaRestrictionView onClose={this.onEditItemClosed} currentInstructorVisaRestriction={this.state.selectedItem} />
        )}
      </>
    );
  }
}

export default withTranslation()(InstructorVisaRestrictionListPage);
